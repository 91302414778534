import * as React from "react"
import { Layout } from '../components/Layout';
import { P, BackgroundSection, Divider } from '../components/styles';
import { CTASection } from '../components/CTASection';
import aboutBannerSlide1Src from '../images/banner/about/Slide1.jpg';
import SEO from '../components/seo';
import aboutPhoto1Src from '../images/photos/about/friends-skiing.jpg';
import aboutPhoto3Src from '../images/photos/about/cooking.jpg';
import aboutPhoto2Src from '../images/photos/about/kids-dog.jpg';
import styled from "@emotion/styled"

const slides = [
  {
      imageSrc: aboutBannerSlide1Src,
  },
];

const OurStorySection = styled.div(({ theme }) => `
    background-color: ${theme.colors.secondary.light};

    .inner {
        margin: 0 auto;
        max-width: 1600px;
    }

    .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5rem;
        text-align: center;

        h1 {
            margin-bottom: 0;
            line-height: 1.3;
        }
    }

    .content {
        padding: ${theme.spacing(4)};
    }

    .content p {
        font-size: 18px;
        line-height: 1.5;
    }

    .photos {
        line-height: 0;
        text-align: center;
    }

    .photos img {
        max-width: 100%;
    }

    @media (min-width: 768px) {

        .inner {
            display: flex;
        }

        .content {
            width: 60%;
            padding-right: ${theme.spacing(6)};
        }

        .photos {
            width: 40%;
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

    }

    @media (min-width: 1024px) {
    }

    @media (min-width: 1200px) {
        .content p {
            font-size: 20px;
            line-height: 1.8;
        }

        .content {
            width: 70%;
        }

        .photos {
            width: 30%;
        }
    }
`);

const OurStoryPage = () => {
    return (
        <Layout bannerSlides={slides} noPadding>
            <SEO title="Our Story" />
            <OurStorySection>
                <div className="inner">
                    <div className="content">
                        <div className="title-container">
                            <h1>The Story Behind Our High-Tech Wandering… Friends, Family & Our Favorite Furry Friends…</h1>
                            <Divider />
                        </div>
                        <P>We started as friends in college who were brought together by our love of adventure and being outdoors.  Those were the days of little to no technology, paper maps, getting lost, discovering epic adventures and making amazing memories.  </P>
                        <P>Over the years we have added partners, children, pets and new friends to the mix.  It became even more important to us to keep on adventuring despite the realities of life.   As the planning got more complicated and technology got better we started realizing that there wasn’t anything out there that really did what we needed to accomplish- planning amazing trips with geographically dispersed friends and family so we could go get lost and not worry about the logistics.</P>
                        <P>As we all started talking (read complaining) about existing software, we came to realize we were at a place both personally and professionally where we could create the software we needed.  So for the past year, each of us took notice of what components the software would need while we were on various adventures ranging from cross-country trips, rock climbing, mountain biking, and overlanding. The very best part is that each of our friends, their partners, children and even pets have contributed in some way to create and launch The Wanderlost Project.</P>
                        <P>Rick<br />
                        Chief Wanderer</P>
                    </div>
                    <div className="photos">
                        <img src={aboutPhoto1Src} alt="4 people with skii helmets on smiling" />
                        <img src={aboutPhoto2Src} alt="A woman with blonde hair and a winter jacket cooking outdoors" />
                        <img src={aboutPhoto3Src} alt="Two kids and a dog with a wanderlost hat on" />
                    </div>
                </div>
            </OurStorySection>

            <BackgroundSection title={'Meet Our Team'}>
                <h3>The Original Wanderers</h3>
                <Divider color="secondary" />
                <P>Brought together by our love of outdoors and a mild disregard for scheduled classes we have been adventuring together for 25 years.  Professionally we live in the software development world, the engineering world and the business world.  We are also photographers, makers, dirtbags, skiers, snowboarders, backpackers, mountain bikers, windsurfers,  paragliders and overlanders.</P>

                <h3>The Wandering Wives/Partners</h3>
                <Divider color="secondary" />
                <P>Brought together by their love for the Original Wanderers, these women have encouraged them to keep adventuring together while bringing their love of outdoors and super women powers to new adventures.</P>

                <h3>The Wanderers in Training</h3>
                <Divider color="secondary" />
                <P>Trained from birth to enjoy adventuring, they bring a wondrous joy, love for learning new skills and discovering new places.  They are mathematicians, readers, dancers, skiers, mountain bikers, climbers, swimmers, hikers, stargazers and master s’mores makers.</P>

                <h3>The Wandering Friends</h3>
                <Divider color="secondary" />
                <P>This madcap group has joined us over the years through various adventures both professional and personal.  Besides being truly awesome people, they bring a wide range of skills from marketing, meal prep and cooking, laughter, beta testing and above all else are willing adventurers.</P>

                <h3>The Furry Wanderers</h3>
                <Divider color="secondary" />
                <P>These steadfast companions keep our herd in check on adventures, provide snuggles on cold nights and force us to seek out of the way adventures that are dog friendly and usually epic.</P>
            </BackgroundSection>
            <CTASection />
        </Layout>
    );
};

export default OurStoryPage;
